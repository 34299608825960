@tailwind base;
@tailwind components;
@tailwind utilities;

@-ms-viewport {
  width: device-width;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  color: #000000;
  font-family: Manrope, 'sans-serif';
  background-color: #ffffff;
  overflow-x: hidden !important;
}

.accordion {
  border: 1px solid #ddd;
  margin: 1rem 0;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
}

.expanded .accordion-content {
  max-height: 500px; /* Adjust as needed */
}
